import React from 'react';

import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import {
  HorizontalYellowLineShape,
  IconLectureTime,
} from '../../Atoms/Icons/Icons';
import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import useMedias from '../../hooks/useMedias';
import DocumentView from '../../Molecules/DocumentView/DocumentView';
import SocialShare from '../../Molecules/SocialShare/SocialShare';

import './ArticleSingle.scss';

const classNames = require('classnames');

const ArticleSingle = ({
  category,
  title,
  summary,
  reading_time,
  reading_time_minutes,
  reading_time_seconds,
  linkTo,
  xhtml,
  image,
  altImage,
  customClass,
  currentNav,
}) => {
  const { processUrl } = useMedias();
  const intl = useIntl();
  const show_summary = true;

  let title_link = linkTo;
  if (title_link.indexOf('.pdf') > 0) {
    title_link = process.env.GATSBY_METADATA_SITE_URL + intl.locale + '/pdf/?file=https:' + linkTo + '&title=' + (removeHtmlTag(title || ''));
  }
  return (
    <div className={classNames('article_single', customClass)}>
      <div className={classNames('visuel', { no_visual: !image })}>
        {image && (
          <a href={linkTo ? title_link : '#'} target={title_link.indexOf('.pdf') > 0 ? '_blank' : '_self'} rel={title_link.indexOf('.pdf') > 0 ? 'noreferrer' : ''}>
            <img
              src={processUrl(image?.image_style_uri?.gatsby_profile_thumbnail)}
              alt={altImage || title}
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              aria-hidden="true"
            />
          </a>

        )}

        {(
          <p className="lecture_time">
            <IconLectureTime
              className="time_icon"
              aria-label={
                intl.formatMessage({ id: 'ariaLabel.time_icon' }) + ' ' +
                reading_time_minutes + ' ' +
                intl.formatMessage({ id: 'ariaLabel.minutes' }) + ' ' +
                reading_time_seconds + ' ' +
                intl.formatMessage({ id: 'ariaLabel.seconds' })
              }
            />
            <span>{reading_time}</span>
            <HorizontalYellowLineShape className="bottom_line" />
          </p>
        )}
      </div>
      <div className={classNames('text', { no_summary: !show_summary })}>
        {(
          <SocialShare
            title={title}
            linkTo={linkTo}
            hidemobile={true}
          />
        )}
        <div className={classNames('title_description')}>
          {
            title_link.indexOf('.pdf') > 0 ? (
              <a href={title_link} target="_blank" rel="noreferrer">
                <h2 className={(!show_summary || !summary) ? 'full' : 'max_lines'}>{title}</h2>
              </a>
            ) : (
              <h2 className={(!show_summary || !summary) ? 'full' : 'max_lines'}>{linkTo ? <Link to={linkTo}>{title}</Link> : title}</h2>
            )
          }
          {(show_summary && summary) && (
            <p dangerouslySetInnerHTML={{ __html: summary }} />
          )}
        </div>
      </div>
    </div>
  );
};

ArticleSingle.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string,
  reading_time: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
  image: PropTypes.object,
};

export default ArticleSingle;
